import { Product, ProductFamily, ProductFamilyOption, SelectedOption } from '@omniafishing/core';
import classNames from 'classnames';
import _ from 'lodash';
import InputNumber from 'rc-input-number';
import 'rc-input-number/assets/index.css';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { useProductFamilyModal } from '../../hooks/use_product_family_modal';
import { useResponsive } from '../../hooks/use_responsive';
import {
  deepClone,
  getProductFamilyUrl,
  isProductInStock,
  setShopifyImgWidth,
  toDollars,
} from '../../lib';
import {
  getProductByPartialSelectedOptions,
  getProductBySelectedOptions,
} from '../../lib/get_product_by_selected_options';
import { SegmentAnalytics } from '../../lib/segment';
import { AddToCartButton } from '../add_to_cart_button/add_to_cart_button';
import { Divider } from '../divider/divider';
import { LinkButton } from '../link_button/link_button';
import styles from './product_family_selector.module.scss';

interface ProductFamilySelectorProps {
  products: Product[];
  productFamily: ProductFamily;
  visible: boolean;
}

export const ProductFamilySelector = (props: ProductFamilySelectorProps) => {
  const { products, productFamily, visible } = props;

  const firstInStockProduct = products.filter((p) => isProductInStock(p))[0];
  const [selectedProduct, setSelectedProduct] = useState<Product>(
    firstInStockProduct || products[0]
  );
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>(
    selectedProduct.shopify_options
  );
  const [quantity, setQuantity] = useState(1);
  const [variantMatch, setVariantMatch] = useState(true);
  const { isMobile } = useResponsive();
  const { hide } = useProductFamilyModal();
  const { affiliateLinkParams } = useOmniaVideo();

  const closeModal = () => {
    hide();
    resetState();
  };

  const resetState = useCallback(() => {
    // timeout to match the fade transition time so it doesn't change before hiding
    setTimeout(() => {
      setSelectedProduct(firstInStockProduct || products[0]);
      setQuantity(1);
      setVariantMatch(true);
    }, 300);
  }, [firstInStockProduct, products]);

  // the modal can be hidden by clicking outside too
  useEffect(() => {
    if (!visible) {
      resetState();
    }
  }, [visible, resetState]);

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleVariantChange('color', event.target.value);
  };

  const onOptionChange = (optionName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleVariantChange(optionName, event.target.value);
  };

  const onQuantityChange = (val: number) => {
    setQuantity(val);
  };

  const handleVariantChange = (optionName: string, value: string) => {
    const newSelectedOptions = deepClone(selectedOptions);
    const optionToChange = newSelectedOptions.filter(
      (selectedOption) => selectedOption.name.toLowerCase() === optionName.toLowerCase()
    )[0];
    optionToChange.value = value;

    const optionsWithoutValues = newSelectedOptions.filter((option) => !option.value);
    if (optionsWithoutValues.length) {
      setVariantMatch(false);
      setSelectedOptions(newSelectedOptions);
      return;
    }

    const match = getProductBySelectedOptions(props.products, newSelectedOptions);

    if (match) {
      setVariantMatch(true);
      setSelectedOptions(newSelectedOptions);
      setSelectedProduct(match);
    } else {
      const otherOptionsToChange = newSelectedOptions.filter(
        (selectedOption) => selectedOption.name.toLowerCase() !== optionName.toLowerCase()
      );
      otherOptionsToChange.forEach((option) => (option.value = ''));
      setVariantMatch(false);
      setSelectedOptions(newSelectedOptions);
    }
  };

  function getUnavailableOptionValues() {
    const optionsWithValues = selectedOptions.filter((option) => !!option.value);
    const optionsWithoutValues = selectedOptions.filter((option) => !option.value);

    const unavailableOptions: { [key: string]: string[] } = {};

    optionsWithoutValues.forEach((optionWithoutValue) => {
      const name = optionWithoutValue.name;
      const productOptions = getproductOptions().filter(
        (option) => option.name.toLowerCase() === name.toLowerCase()
      )[0];
      const valuesWithoutMatches = productOptions.values
        .map((value) => value.value)
        .filter((value) => {
          const optionsToTest = [
            ...optionsWithValues,
            {
              name,
              value,
            },
          ];
          const match = getProductByPartialSelectedOptions(props.products, optionsToTest);
          return !match;
        });

      unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
    });

    return unavailableOptions;
  }

  function getOtherAvailableOptionValues() {
    const { products } = props;

    const unavailableOptions: { [key: string]: string[] } = {};

    selectedOptions.forEach((currentOption) => {
      const selectedOptionsWithoutThisOption = selectedOptions.filter(
        (option) => option.name !== currentOption.name
      );
      const name = currentOption.name;
      const productOptions = getproductOptions().filter(
        (option) => option.name.toLowerCase() === name.toLowerCase()
      )[0];
      const valuesWithoutMatches = productOptions.values
        .map((value) => value.value)
        .filter((value) => {
          const optionsToTest = [
            ...selectedOptionsWithoutThisOption,
            {
              name,
              value,
            },
          ];
          const match = getProductByPartialSelectedOptions(products, optionsToTest);
          return !match;
        });

      unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
    });

    return unavailableOptions;
  }

  function isUnavailable(optionName: string, value: string) {
    let unavailableOptions = getUnavailableOptionValues();
    const optionsWithoutValues = selectedOptions.filter((option) => !option.value);
    if (!optionsWithoutValues.length) {
      unavailableOptions = getOtherAvailableOptionValues();
    }

    const name = optionName.toLowerCase();
    return unavailableOptions[name] && unavailableOptions[name].indexOf(value) > -1;
  }

  function getproductOptions(): ProductFamilyOption[] {
    const ProductFamilyOptionsFromProducts = [...productFamily.options];

    const productOptions: Record<string, string[]> = {};

    const productOptionsNames = _.flatten(
      products.map((p) => p.shopify_options.map((o) => o.name))
    );
    productOptionsNames.forEach((name) => {
      if (!productOptions[name]) {
        productOptions[name] = [];
      }
    });

    products.forEach((product) => {
      product.shopify_options.forEach((option) => {
        if (productOptions[option.name].indexOf(option.value) === -1) {
          productOptions[option.name].push(option.value);
        }
      });
    });

    return ProductFamilyOptionsFromProducts.map((option) => {
      return {
        ...option,
        values: option.values.filter(
          (value) => productOptions[option.name].indexOf(value.value) > -1
        ),
      };
    });
  }

  function getcolorOptions() {
    const colorOption = getproductOptions().filter(
      (option) => option.name.toLowerCase() === 'color'
    )[0];

    if (!colorOption) {
      return [];
    }

    return colorOption.values.map((value) => {
      return {
        colorName: value.value,
        imgSrc: value.src,
      };
    });
  }

  function getSelectedColorOption() {
    return selectedOptions.filter(
      (selectedOption) => selectedOption.name.toLowerCase() === 'color'
    )[0];
  }

  function getColorPicker() {
    const colorOptions = getcolorOptions();
    const selectedColorOption = getSelectedColorOption();
    if (!colorOptions.length) {
      return null;
    }

    return (
      <fieldset className={classNames(styles.fieldset, styles.fieldset__colors)}>
        <legend className={styles.optionLegend}>Color:</legend>
        <div className={styles.colorsWrapper}>
          {colorOptions.map((colorOption) => {
            const unavailable = isUnavailable('color', colorOption.colorName);
            const isSelected = selectedColorOption.value === colorOption.colorName;
            return (
              <label className={styles.colorLabel} key={colorOption.colorName}>
                <div>
                  <img
                    alt={colorOption.colorName}
                    src={setShopifyImgWidth(colorOption.imgSrc, 300)}
                    title={colorOption.colorName}
                    className={classNames(styles.colorLabel, {
                      [styles.optionUnavailable]: unavailable,
                      [styles.optionSelected]: isSelected,
                    })}
                  />
                </div>
                <input
                  type="radio"
                  name="color"
                  value={colorOption.colorName}
                  checked={isSelected}
                  onChange={onColorChange}
                  className={styles.optionRadio}
                />
              </label>
            );
          })}
        </div>
      </fieldset>
    );
  }

  const nonColorOptions = getproductOptions().filter((o) => o.name.toLowerCase() !== 'color');
  const hasColorOption = !!getcolorOptions().length;
  const showImage = !hasColorOption || !isMobile;
  const hasInventory = selectedProduct?.inventory != null && selectedProduct.inventory_tracked;
  const outOfStock = !isProductInStock(selectedProduct);
  const inventoryText =
    selectedProduct.inventory > 5 || !selectedProduct.inventory_tracked
      ? '5+'
      : selectedProduct.inventory;
  const isHidden = selectedProduct?.hidden;

  let addCartText = 'Add to Cart';
  if (outOfStock) {
    addCartText = 'Out of Stock';
  }
  if (!variantMatch) {
    addCartText = 'Choose Options';
  }
  if (isHidden) {
    addCartText = 'Not Carried';
  }

  const isNewRelease = productFamily.tags.indexOf('new_releases') > -1;

  const selectedProductPrice = toDollars(selectedProduct.price);
  const inputDisabled = !variantMatch || outOfStock || isHidden;

  return (
    <section>
      <div className={styles.headingWrapper}>
        <div>
          <h1 className={styles.heading} id={selectedProduct.sku}>
            {productFamily.title}
            {isNewRelease && <span className={styles.newReleaseTag}>NEW RELEASE</span>}
          </h1>
          <p className={styles.variantTitle}>
            {selectedProduct.shopify_options.map((option) => option.value).join(' / ')}
          </p>
        </div>
      </div>

      <Divider />

      <div className={styles.options}>
        {showImage && (
          <div className={styles.imgWrapper}>
            <img src={setShopifyImgWidth(selectedProduct.img_url, 300)} alt="" />
          </div>
        )}
        <div
          className={classNames(styles.optionsSelector, {
            [styles.optionsSelector__hasColors]: !showImage,
          })}
        >
          {getColorPicker()}

          {nonColorOptions.map((option) => {
            const selectedOption = selectedOptions.filter(
              (stateSelectedOption) => stateSelectedOption.name === option.name
            )[0];
            return (
              <fieldset className={styles.fieldset} key={option.name}>
                <legend className={styles.optionLegend}>{_.capitalize(option.name)}: </legend>

                {option.values
                  .map((value) => value.value)
                  .map((value) => {
                    const unavailable = isUnavailable(option.name, value);
                    const isSelected = selectedOption.value === value;
                    return (
                      <label
                        key={value}
                        className={classNames(styles.textLabel, {
                          [styles.optionUnavailable]: unavailable,
                          [styles.optionSelected]: isSelected,
                        })}
                      >
                        <span>{value}</span>
                        <input
                          type="radio"
                          name={option.name}
                          value={value}
                          checked={isSelected}
                          onChange={onOptionChange(option.name)}
                          className={styles.optionRadio}
                        />
                      </label>
                    );
                  })}
              </fieldset>
            );
          })}

          {hasInventory && <p className={styles.stock}>Stock: {inventoryText}</p>}

          <Divider margin={4} />

          <div>
            <div className={styles.priceQuantity}>
              <p className={styles.price}>
                <span
                  className={classNames({
                    [styles.price__sale]: selectedProduct.on_sale,
                  })}
                >
                  {selectedProductPrice}
                </span>
                {selectedProduct.on_sale && (
                  <s className={styles.priceCompare}>
                    {toDollars(selectedProduct.compare_at_price)}
                  </s>
                )}
              </p>

              <div className={styles.quantityWrapper}>
                Qty:
                <InputNumber
                  min={1}
                  max={
                    hasInventory && selectedProduct.inventory > 0 ? selectedProduct.inventory : 99
                  }
                  value={quantity}
                  onChange={onQuantityChange}
                  className={classNames(styles.quantityInput, {
                    [styles.disabled]: inputDisabled,
                  })}
                  disabled={inputDisabled}
                />
              </div>
            </div>

            <Divider margin={4} />

            <div className={styles.bottomButtons}>
              <AddToCartButton
                product={selectedProduct}
                disabled={inputDisabled}
                className={styles.actionButton}
                quantity={quantity}
                onAddedToCart={() => {
                  closeModal();
                }}
                color="orange"
              >
                {addCartText}
              </AddToCartButton>

              <LinkButton
                href={getProductFamilyUrl(productFamily.handle) + affiliateLinkParams}
                className={styles.fullDetails}
                color="white"
                onClick={() => {
                  closeModal();
                  SegmentAnalytics.productClick({
                    productOrProductFamily: productFamily,
                    show_modal: false,
                    position: null,
                  });
                }}
              >
                Full Product Details <IoIosArrowForward className="text ml-1" />
              </LinkButton>

              {/*
              {outOfStock && (
                <Button onClick={onOutOfStockClick}>
                  <MailOutlined />
                  Email Me
                </Button>
              )}
              */}
            </div>
          </div>

          {/* <ShippingEstimator
            product={selectedProduct}
            availableForSale={!outOfStock}
            className={styles.shippingEstimator}
          /> */}
        </div>
      </div>
    </section>
  );
};
