import { OmniaResponse, OmniaVideo, OmniaVideoItem, TechniqueFull } from '@omniafishing/core';
import { createContext, useEffect, useState } from 'react';
import { getEnv } from '../env';
import { getQueryParams } from '../lib';

interface OmniaVideoProviderProps {
  children: React.ReactNode;
}

export const initialOmniaVideo = {
  techniques: [] as TechniqueFull[],
};
interface OmniaVideoContextValue {
  omniaVideo: OmniaVideo;
  omniaVideoItems: OmniaVideoItem[];
  loading: boolean;
  error: boolean;
}

export const OmniaVideoContext = createContext<OmniaVideoContextValue>({
  omniaVideo: null,
  omniaVideoItems: [],
  loading: false,
  error: false,
});

const env = getEnv();

export const OmniaVideoProvider = (props: OmniaVideoProviderProps) => {
  const { children } = props;
  const [omniaVideo, setOmniaVideo] = useState<OmniaVideo>(null);
  const [omniaVideoItems, setOmniaVideoItems] = useState<OmniaVideoItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const apiUrl = env.API_BASE_URL;
  const { id } = getQueryParams();

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetch(`${apiUrl}v1/omnia_videos/embed/${id}`)
      .then((response) => response.json())
      .then(
        (
          data: OmniaResponse<{
            omnia_video: OmniaVideo;
            omnia_video_items: OmniaVideoItem[];
          }>
        ) => {
          setOmniaVideo(data.data.omnia_video);
          setOmniaVideoItems(data.data.omnia_video_items);
          setLoading(false);
        }
      )
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [id, apiUrl]);

  const value: OmniaVideoContextValue = {
    omniaVideo,
    omniaVideoItems,
    loading,
    error,
  };

  return <OmniaVideoContext.Provider value={value}>{children}</OmniaVideoContext.Provider>;
};
