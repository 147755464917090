import { CheckoutFragmentText, VariantFragmentText } from '@omniafishing/core';
import { gql } from 'graphql-request';

export const VariantFragment = gql`
  ${VariantFragmentText}
`;

export const CheckoutFragment = gql`
  ${CheckoutFragmentText}
`;
