import { Checkout } from '@omniafishing/core';
import { gql } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import { QueryIds, shopifyClient } from '../clients';
import { CheckoutFragment } from '../fragments';

const checkoutLineItemsUpdateMutation = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export interface LineItemToUpdate {
  id: string;
  quantity: number;
}
export interface UpdateLineItemsResponse {
  checkoutLineItemsUpdate: {
    checkout: Checkout;
  };
}

export const useUpdateLineItemQuantity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { checkoutId: string; lineItems: LineItemToUpdate[] }) => {
      const { checkoutId, lineItems } = args;

      const res = await shopifyClient.request<UpdateLineItemsResponse>(
        checkoutLineItemsUpdateMutation,
        {
          checkoutId,
          lineItems,
        }
      );

      return res.checkoutLineItemsUpdate.checkout;
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(QueryIds.checkout, data);
      },
    }
  );
};
