import { GraphQLClient } from 'graphql-request';

export const shopifyClient = new GraphQLClient(
  'https://shop.omniafishing.com/api/2022-10/graphql.json',
  {
    headers: {
      'X-Shopify-Storefront-Access-Token': '422b2444dfe54529ddc6697d888017d0',
    },
  }
);

export enum QueryIds {
  checkout = 'checkout',
}
