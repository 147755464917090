import { getQueryParams } from './lib';

const SHARED_ENV = {
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1Ijoib21uaWFmaXNoaW5nIiwiYSI6ImNqbHk0aTBkeDBpNDEzcW1nODAyZnYxMWMifQ.71E8mNoUSkBTfL2HAjV-LA',
};

const ENV_LOCALHOST = {
  SEGMENT_WRITE_KEY: '8C8V1BXdw80eqU7pXQg8JL6YKsSph9iR',
};
const ENV_STAGING = {
  SEGMENT_WRITE_KEY: '8C8V1BXdw80eqU7pXQg8JL6YKsSph9iR',
};
const ENV_PRODUCTION = {
  SEGMENT_WRITE_KEY: 'nEkRqL5SGIy1UJTIajcHwFplMLbGnE9u',
};

export const getEnv = () => {
  const { env } = getQueryParams();
  let API_BASE_URL = 'https://prod-api.omniafishing.com/';
  let envValues = ENV_PRODUCTION;
  if (env === 'staging') {
    API_BASE_URL = 'https://stage-api.omniafishing.com/';
    envValues = ENV_STAGING;
  } else if (env === 'localhost') {
    API_BASE_URL = `http://localhost:3000/`;
    envValues = ENV_LOCALHOST;
  }

  return {
    ...SHARED_ENV,
    ENV: env,
    API_BASE_URL,
    ...envValues,
  };
};
