import { OmniaVideoItemProduct } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { formatSecondsToMinutes, setShopifyImgWidth, toDollars } from '../../lib';
import { SegmentAnalytics } from '../../lib/segment';
import { AddToCartButton } from '../add_to_cart_button/add_to_cart_button';
import { Spacer } from '../spacer/spacer';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemProduct = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemProduct }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump, position } = props;
  const { item, start_sec } = omniaVideoItem;
  const { title, img_url, price, on_sale, compare_at_price, in_stock } = item;
  const addCartText = in_stock ? 'Add to Cart' : 'Out of Stock';

  const clickHandler = () => {
    onClick?.();
    SegmentAnalytics.productClick({
      productOrProductFamily: item,
      show_modal: true,
      position,
    });
  };

  return (
    <>
      <div onClick={clickHandler} className={styles.img}>
        <img
          src={setShopifyImgWidth(img_url, 200)}
          alt={''}
          loading="lazy"
          width="200"
          height="200"
        />
      </div>

      <div className={styles.text}>
        <p className={styles.title} onClick={clickHandler}>
          {title}
        </p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>

      <div className={styles.buttonPrice}>
        <AddToCartButton
          product={item}
          onClick={clickHandler}
          className={classNames(styles.button, {
            [styles.button__disabled]: !in_stock,
          })}
          quantity={1}
          disabled={!in_stock}
        >
          {addCartText}
        </AddToCartButton>

        <Spacer size={12} />

        <p className={styles.price}>
          <span
            className={classNames({
              [styles.price__sale]: on_sale,
            })}
          >
            ${price}
          </span>
          {on_sale && <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>}
        </p>
      </div>
    </>
  );
};
