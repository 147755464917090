import { useContext } from 'react';
import { ReferenceDataContext } from '../providers/reference_data_provider';

export const useReferenceData = () => {
  const {
    techniques,
    species,
    seasons,
    season_groups,
    categories,
    brands,
    structures,
    states,
    forages,
  } = useContext(ReferenceDataContext);

  const categoryBySubcatName = (subcategory_name: string) => {
    return categories.find((c) => c.subcategories.some((s) => s.name === subcategory_name));
  };

  return {
    techniques,
    species,
    seasons,
    season_groups,
    categories,
    brands,
    structures,
    states,
    forages,
    categoryBySubcatName,
  };
};
