import { ProductFamily } from '@omniafishing/core';
import { createContext, useState } from 'react';

interface ModalProviderProps {
  children: React.ReactNode;
}

interface ModalContextValue {
  productFamilyModalVisible: boolean;
  productFamilyModalProductFamily: ProductFamily;
  showProductFamilyModal: (productFamily: ProductFamily) => void;
  hideProductFamilyModal: () => void;
}

export const ModalContext = createContext<ModalContextValue>(null);

export const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;
  const [productFamilyModalVisible, setProductFamilyModalVisible] = useState(false);
  const [productFamilyModalProductFamily, setProductFamilyModalProductFamily] =
    useState<ProductFamily>();

  const showProductFamilyModal = (productFamily: ProductFamily) => {
    setProductFamilyModalVisible(true);
    setProductFamilyModalProductFamily(productFamily);
  };

  const hideProductFamilyModal = () => {
    setProductFamilyModalVisible(false);
  };

  const value: ModalContextValue = {
    productFamilyModalVisible,
    productFamilyModalProductFamily,
    showProductFamilyModal,
    hideProductFamilyModal,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
