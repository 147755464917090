import { OmniaVideoItemCategory, OmniaVideoItemSubcategory } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { useReferenceData } from '../../hooks/use_reference_data';
import { formatSecondsToMinutes, getCategoryUrl, getSubcategoryUrl } from '../../lib';
import { LinkButton } from '../link_button/link_button';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemCategory = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemCategory | OmniaVideoItemSubcategory }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump } = props;
  const { categoryBySubcatName } = useReferenceData();

  const { item, start_sec, item_type } = omniaVideoItem;
  const { display_name, image, name } = item;
  const { affiliateLinkParams } = useOmniaVideo();
  const category = categoryBySubcatName(name);

  const url =
    (item_type === 'Category' ? getCategoryUrl(item) : getSubcategoryUrl(category, item)) +
    affiliateLinkParams;

  return (
    <>
      <div onClick={onClick} className={styles.img}>
        <img src={image} alt="" />
      </div>

      <div className={styles.text}>
        <p className={styles.title} onClick={onClick}>
          {display_name}
        </p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>

      <div className={styles.buttonPrice}>
        <LinkButton href={url} onClick={onClick} className={classNames(styles.button)}>
          Learn More
        </LinkButton>
      </div>
    </>
  );
};
