import {
  Brand,
  Category,
  Forage,
  OmniaResponse,
  Season,
  SeasonGroup,
  Species,
  State,
  Structure,
  TechniqueFull,
} from '@omniafishing/core';
import { createContext, useEffect, useState } from 'react';
import { axiosOmniaV1 } from '../axios_omnia';

interface ReferenceDataProviderProps {
  children: React.ReactNode;
}

export const initialReferenceData = {
  techniques: [] as TechniqueFull[],
  species: [] as Species[],
  seasons: [] as Season[],
  season_groups: [] as SeasonGroup[],
  categories: [] as Category[],
  brands: [] as Brand[],
  structures: [] as Structure[],
  states: [] as State[],
  forages: [] as Forage[],
};
type ReferenceDataContextValue = typeof initialReferenceData;

export const ReferenceDataContext = createContext<ReferenceDataContextValue>(initialReferenceData);

export const ReferenceDataProvider = (props: ReferenceDataProviderProps) => {
  const { children } = props;
  const [referenceData, setReferenceData] = useState(initialReferenceData);

  const {
    techniques,
    species,
    seasons,
    season_groups,
    categories,
    brands,
    structures,
    states,
    forages,
  } = referenceData;

  useEffect(() => {
    axiosOmniaV1.get<OmniaResponse<typeof initialReferenceData>>('reference_data').then((res) => {
      setReferenceData(res.data.data);
    });
  }, []);

  const value: ReferenceDataContextValue = {
    techniques,
    species,
    seasons,
    season_groups,
    categories,
    brands,
    structures,
    states,
    forages,
  };

  return <ReferenceDataContext.Provider value={value}>{children}</ReferenceDataContext.Provider>;
};
