import { Product, SelectedOption } from '@omniafishing/core';
import _ from 'lodash';

export function getProductBySelectedOptions(
  products: Product[],
  selectedOptions: SelectedOption[]
) {
  if (selectedOptions == null) {
    return null;
  }

  const selectedOptionsLowercase = selectedOptions.map(option => {
    return {
      name: option.name.toLowerCase(),
      value: option.value.toLowerCase(),
    };
  });
  const sortedSelectedOptions = _.sortBy(selectedOptionsLowercase, 'name');

  const matchingProduct = products.filter(product => {
    const shopifyOptionsLowercase = product.shopify_options.map(option => {
      return {
        name: option.name.toLowerCase(),
        value: option.value.toLowerCase(),
      };
    });
    const sortedVariantSelectedOptions = _.sortBy(shopifyOptionsLowercase, 'name');

    return _.isEqual(sortedSelectedOptions, sortedVariantSelectedOptions);
  })[0];

  return matchingProduct || null;
}

export function getProductByPartialSelectedOptions(
  products: Product[],
  selectedOptions: SelectedOption[]
) {
  if (selectedOptions == null) {
    return null;
  }

  const selectedOptionsLowercase = selectedOptions.map(option => {
    return {
      name: option.name.toLowerCase(),
      value: option.value.toLowerCase(),
    };
  });
  const sortedSelectedOptions = _.sortBy(selectedOptionsLowercase, 'name');
  const selectedOptionsNames = selectedOptionsLowercase.map(option => option.name);

  const matchingProduct = products.filter(product => {
    const variantSelectedOptionsLowercase = product.shopify_options.map(option => {
      return {
        name: option.name.toLowerCase(),
        value: option.value.toLowerCase(),
      };
    });
    const sortedVariantSelectedOptions = _.sortBy(variantSelectedOptionsLowercase, 'name');

    const matchingVariantOptions = sortedVariantSelectedOptions.filter(
      option => selectedOptionsNames.indexOf(option.name) > -1
    );

    return _.isEqual(sortedSelectedOptions, matchingVariantOptions);
  })[0];

  return matchingProduct || null;
}
