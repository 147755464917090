import { OmniaVideoItem } from '@omniafishing/core';
import classNames from 'classnames';
import styles from './video_item.module.scss';
import { VideoItemAmbassador } from './video_item_ambassador';
import { VideoItemBrand } from './video_item_brand';
import { VideoItemCategory } from './video_item_category';
import { VideoItemProduct } from './video_item_product';
import { VideoItemProductFamily } from './video_item_product_family';
import { VideoItemSpecies } from './video_item_species';
import { VideoItemTechnique } from './video_item_technique';
import { VideoItemWaterbody } from './video_item_waterbody';

export interface VideoItemProps {
  omniaVideoItem: OmniaVideoItem;
  onClick: () => void;
  onJumpClick: () => void;
  selected: boolean;
  showJump: boolean;
  position: number;
}

export const VideoItem = (props: VideoItemProps) => {
  const { omniaVideoItem, selected } = props;
  const { comments, item_type } = omniaVideoItem;

  return (
    <div
      className={classNames(styles.item, {
        [styles.item__selected]: selected,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.content__selected]: selected,
        })}
      >
        {(() => {
          switch (item_type) {
            case 'ProductFamily':
              return <VideoItemProductFamily {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Product':
              return <VideoItemProduct {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Waterbody':
              return <VideoItemWaterbody {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Species':
              return <VideoItemSpecies {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Technique':
              return <VideoItemTechnique {...props} omniaVideoItem={omniaVideoItem} />;
            case 'User':
              return <VideoItemAmbassador {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Brand':
              return <VideoItemBrand {...props} omniaVideoItem={omniaVideoItem} />;
            case 'Category':
            case 'Subcategory':
              return <VideoItemCategory {...props} omniaVideoItem={omniaVideoItem} />;
            default:
              return null;
          }
        })()}
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
