import { Checkout, LineItem } from '@omniafishing/core';
import { gql } from 'graphql-request';
import { sortBy } from 'lodash';
import { useQuery } from 'react-query';
import { QueryIds, shopifyClient } from '../clients';
import { CheckoutFragment } from '../fragments';
import { isBase64 } from '../lib/base64';

export const sortLineItems = (lineItems: LineItem[]) =>
  sortBy(lineItems, (lineItem) => {
    const lineItemId = isBase64(lineItem.id) ? atob(lineItem.id) : lineItem.id;
    return Number(lineItemId.replace('gid://shopify/CheckoutLineItem/', '').split('?')[0]);
  });

export const filterLineItems = (lineItems: LineItem[]) =>
  lineItems.filter((l) => l != null && l.variant != null);

const prepareLineItems = (lineItems: LineItem[]) => filterLineItems(sortLineItems(lineItems));

export const useCheckout = (checkoutId: string) => {
  return useQuery(
    QueryIds.checkout,
    async () => {
      const res = await shopifyClient.request<{ node: Checkout }>(
        gql`
          query checkoutFetch($checkoutId: ID!) {
            node(id: $checkoutId) {
              ... on Checkout {
                ...CheckoutFragment
              }
            }
          }
          ${CheckoutFragment}
        `,
        {
          checkoutId,
        }
      );
      return res.node;
    },
    {
      enabled: checkoutId != null,
      select: (checkout) => ({
        lineItems: prepareLineItems(checkout.lineItems.edges.map((e) => e.node)),
        subtotalPrice: checkout.subtotalPrice.amount,
        lineItemsSubtotal: checkout.lineItemsSubtotalPrice.amount,
        discounts: checkout.discountApplications.edges.map((edge) => edge.node),
        giftCards: checkout.appliedGiftCards,
        webUrl: checkout.webUrl,
      }),
      placeholderData: {
        id: null,
        lineItems: {
          edges: [],
        },
        subtotalPrice: {
          amount: null,
        },
        lineItemsSubtotalPrice: {
          amount: null,
        },
        appliedGiftCards: [],
        discountApplications: {
          edges: [],
        },
        webUrl: null,
        completedAt: null,
      },
    }
  );
};
