import { LineItem } from '@omniafishing/core';
import { debounce } from 'lodash';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { useRemoveFromCart } from '../../hooks/use_remove_from_cart';
import { useUpdateLineItemQuantity } from '../../hooks/use_update_line_item_quantity';
import { getProductUrl, setShopifyImgWidth, toDollars } from '../../lib';
import { CartContext } from '../../providers/cart_provider';

export interface CartItemProps {
  lineItem: LineItem;
}

export const CartItem = (props: CartItemProps) => {
  const { lineItem } = props;
  const { variant, discountAllocations, quantity, id } = lineItem;

  const { checkoutId } = useContext(CartContext);
  const removeFromCartMutation = useRemoveFromCart();
  const updateQuantityMutation = useUpdateLineItemQuantity();
  const { affiliateLinkParams } = useOmniaVideo();

  let price = Number(variant.price.amount) * quantity;
  const totalDiscounts = discountAllocations.reduce((sum, allocation) => {
    return sum + Number(allocation.allocatedAmount.amount);
  }, 0);
  if (totalDiscounts > 0) {
    price = price - totalDiscounts;
  }
  const priceText = price > 0 ? toDollars(price) : 'FREE';

  const [quantityVal, setQuantityVal] = useState(quantity);

  const onQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value);
    setQuantityVal(val);
    debouncedUpdateQuantityMutation.current(val);
  };

  const debouncedUpdateQuantityMutation = useRef(
    debounce((quantity: number) => {
      console.log('mutating', quantity);
      updateQuantityMutation.mutate({
        checkoutId,
        lineItems: [
          {
            id,
            quantity,
          },
        ],
      });
    }, 1000)
  );

  const removeLineItem = () => {
    removeFromCartMutation.mutate({
      checkoutId,
      lineItemIds: [id],
    });
  };

  return (
    <div className="flex gap-4 p-5 text-xs uppercase">
      <div className="flex-shrink-0 flex-grow-0">
        <a
          href={
            getProductUrl(variant.product.handle, variant.selectedOptions) + affiliateLinkParams
          }
          target="_blank"
          rel="noreferrer"
        >
          <img src={setShopifyImgWidth(variant?.image?.url, 200)} alt="" width="100" />
        </a>
      </div>
      <div>
        <p className="mb-1">
          <a
            href={
              getProductUrl(variant.product.handle, variant.selectedOptions) + affiliateLinkParams
            }
            target="_blank"
            rel="noreferrer"
            className=" text-omnia-blue hover:underline"
          >
            {lineItem.title} - {variant.title}
          </a>
        </p>
        <p className="mb-2 text-sm font-bold">{priceText}</p>

        <div className="flex gap-4">
          <input
            type="number"
            min={1}
            value={quantityVal}
            onChange={onQuantityChange}
            name="quantity"
            disabled={updateQuantityMutation.isLoading}
            className="w-[70px] border p-2"
          />
          <button onClick={removeLineItem} className={'uppercase text-gray-400 hover:underline'}>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
