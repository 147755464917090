import classNames from 'classnames';

export type ButtonColors = 'blue' | 'orange' | 'white';

export interface ButtonProps extends Partial<Omit<HTMLButtonElement, 'children'>> {
  children: React.ReactNode;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  color?: ButtonColors;
}

export const buttonClassNames = (color: ButtonColors, disabled?: boolean) => {
  const buttonColor = color ?? 'blue';
  const classes: Record<ButtonColors, string> = {
    blue: 'bg-omnia-blue hover:bg-omnia-blue-hover text-white',
    orange: 'bg-orange-500 hover:bg-orange-600 text-white',
    white: 'bg-white text-black border-current border',
  };
  const colorClasses = disabled ? 'bg-stone-200 text-black' : classes[buttonColor];

  return ['inline-flex rounded py-2 px-4 text-xs font-termina', colorClasses];
};

export const Button = (props: ButtonProps) => {
  const { children, onClick, type, color, disabled } = props;

  return (
    <button
      type={type ?? 'button'}
      className={classNames('transition-colors', buttonClassNames(color, disabled))}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
