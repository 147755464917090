import queryString from 'query-string';
import { useContext } from 'react';
import { OmniaVideoContext } from '../providers/omnia_video_provider';

export const useOmniaVideo = () => {
  const { omniaVideo, omniaVideoItems, loading, error } = useContext(OmniaVideoContext);
  const affiliateLinkParams = omniaVideo?.affiliate
    ? '?' +
      queryString.stringify({
        utm_medium: 'omnia-affiliate',
        utm_source: omniaVideo.affiliate.campaign_source,
      })
    : '';

  return {
    omniaVideo,
    omniaVideoItems,
    affiliateLinkParams,
    loading,
    error,
  };
};
