import { OmniaVideoItemAmbassador } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import {
  formatSecondsToMinutes,
  getAmbassadorUrl,
  getUserFullname,
  NAME_FALLBACK,
} from '../../lib';
import { LinkButton } from '../link_button/link_button';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemAmbassador = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemAmbassador }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump } = props;
  const { item, start_sec } = omniaVideoItem;
  const { user } = item;
  const { affiliateLinkParams } = useOmniaVideo();
  const url = getAmbassadorUrl(item) + affiliateLinkParams;

  return (
    <>
      <div className={styles.img}>
        <UserProfileImg image={user.image} imageSize={50} />
      </div>

      <div className={styles.text}>
        <p className={styles.title}>{getUserFullname(user) || NAME_FALLBACK}</p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>

      <div className={styles.buttonPrice}>
        <LinkButton href={url} onClick={onClick} className={classNames(styles.button)}>
          Learn More
        </LinkButton>
      </div>
    </>
  );
};
