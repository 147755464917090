import { OmniaVideoItemTypes, Product, ProductFamily } from '@omniafishing/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { getEnv } from '../env';
import { getProductFamilyUrl, getProductUrl } from '../lib';

const env = getEnv();
const segment = AnalyticsBrowser.load({ writeKey: env.SEGMENT_WRITE_KEY });

export const INTERNALS = {
  getAnalytics: () => segment,
};

export function isProductFamily(
  productOrFamily: Product | ProductFamily
): productOrFamily is ProductFamily {
  return (productOrFamily as ProductFamily).handle != null;
}

export enum AddToCartAreas {
  MAIN_VIEW = 'main-view',
  QUICK_VIEW = 'quick-view',
  REPORTS_VIEW = 'reports-view',
  VARIANTS_VIEW = 'variants-view',
}

type LocationTypes = 'Embed';

class SegmentAnalyticsClass {
  addToCart(product: Product, area: AddToCartAreas, locationType?: LocationTypes) {
    const segment = INTERNALS.getAnalytics();
    const pageType = locationType;
    const { sku, shopify_product_handle, shopify_product_id, subcategory_name, brand, title } =
      product;
    segment.track('Add to Cart', {
      product_brand: brand,
      product_family_id: shopify_product_id,
      product_subcategory: subcategory_name,
      product_slug: shopify_product_handle,
      product_title: title,
      product_sku: sku,
      area,
      location: window.location.href,
      locationType: pageType,
    });
  }

  productClick(args: {
    productOrProductFamily: Product | ProductFamily;
    show_modal: boolean;
    position: number;
    locationType?: LocationTypes;
  }) {
    const { productOrProductFamily, show_modal, position, locationType } = args;
    const segment = INTERNALS.getAnalytics();

    const isPf = isProductFamily(productOrProductFamily);
    segment.track('Product Click', {
      product_family_id: isPf
        ? String(productOrProductFamily.id)
        : productOrProductFamily.shopify_product_id,
      product_brand: productOrProductFamily.brand,
      product_subcategory: isPf
        ? productOrProductFamily.subcategory
        : productOrProductFamily.subcategory_name,
      product_sku: isPf ? null : productOrProductFamily.sku,
      product_slug: isPf
        ? productOrProductFamily.handle
        : productOrProductFamily.shopify_product_handle,
      product_title: productOrProductFamily.title,
      locationType: locationType,
      show_modal,
      position,
    });
  }

  productFamilyListViewed(params: {
    coupon: string;
    list_id: string;
    productFamilies: ProductFamily[];
  }) {
    const segment = INTERNALS.getAnalytics();
    const { list_id, productFamilies, coupon } = params;
    if (!productFamilies.length) {
      return;
    }

    segment.track('Product List Viewed', {
      list_id,
      category: 'families',
      products: productFamilies.slice(0, 20).map((pf, i) => ({
        product_id: pf.id.toString(),
        sku: pf.handle,
        category: pf.subcategory,
        name: pf.title,
        brand: pf.brand,
        variant: null,
        price: pf.price_max,
        quantity: 1,
        coupon,
        position: i,
        url: window.location.origin + getProductFamilyUrl(pf.handle),
        image_url: pf.img_url,
      })),
    });
  }

  productListViewed(params: { coupon: string; list_id: string; products: Product[] }) {
    const segment = INTERNALS.getAnalytics();
    const { list_id, products, coupon } = params;

    if (!products.length) {
      return;
    }

    segment.track('Product List Viewed', {
      list_id,
      category: 'products',
      products: products.slice(0, 20).map((p, i) => ({
        product_id: p.id.toString(),
        sku: p.sku,
        category: p.subcategory_name,
        name: p.title,
        brand: p.brand,
        variant: p.shopify_variant_id,
        price: parseFloat(p.price),
        quantity: 1,
        coupon,
        position: i,
        url: window.location.origin + getProductUrl(p.shopify_product_handle, p.shopify_options),
        image_url: p.img_url,
      })),
    });
  }

  shoppableVideoItemClick(args: {
    video_title: string;
    video_pub_date: string;
    video_slug: string;
    is_livestream: boolean;
    is_top_item: boolean;
    item_id: string;
    item_type: OmniaVideoItemTypes;
  }) {
    const {
      video_title,
      video_pub_date,
      video_slug,
      is_livestream,
      is_top_item,
      item_id,
      item_type,
    } = args;
    const segment = INTERNALS.getAnalytics();
    segment.track('Shoppable Video Item Click', {
      video_title,
      video_pub_date,
      video_slug,
      is_livestream,
      is_top_item,
      item_id,
      item_type,
    });
  }

  shoppableVideoPlaybackStatus(args: {
    video_title: string;
    video_pub_date: string;
    video_slug: string;
    status: string;
  }) {
    const { video_title, video_pub_date, video_slug, status } = args;
    const segment = INTERNALS.getAnalytics();
    segment.track('Shoppable Video Playback Status', {
      video_title,
      video_pub_date,
      video_slug,
      status,
    });
  }
}

export const SegmentAnalytics = new SegmentAnalyticsClass();
