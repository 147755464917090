import { Checkout } from '@omniafishing/core';
import { gql } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import { QueryIds, shopifyClient } from '../clients';
import { CheckoutFragment } from '../fragments';

const checkoutLineItemsRemoveMutation = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export interface RemoveLineItemResponse {
  checkoutLineItemsRemove: {
    checkout: Checkout;
  };
}

export const useRemoveFromCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { checkoutId: string; lineItemIds: string[] }) => {
      const { checkoutId, lineItemIds } = args;

      const res = await shopifyClient.request<RemoveLineItemResponse>(
        checkoutLineItemsRemoveMutation,
        {
          checkoutId,
          lineItemIds,
        }
      );

      return res.checkoutLineItemsRemove.checkout;
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(QueryIds.checkout, data);
      },
    }
  );
};
