import classNames from 'classnames';
import { buttonClassNames, ButtonColors } from '../button/button';

interface LinkButtonProps extends Partial<Omit<HTMLAnchorElement, 'children'>> {
  children: React.ReactNode;
  onClick?: () => void;
  color?: ButtonColors;
}

export const LinkButton = (props: LinkButtonProps) => {
  const { href, children, onClick, color } = props;

  return (
    <a
      href={href}
      className={classNames('flex items-center', buttonClassNames(color))}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
