import { OmniaVideoItemSpecies } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { formatSecondsToMinutes, getImgixPath, getSpeciesUrl } from '../../lib';
import { LinkButton } from '../link_button/link_button';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemSpecies = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemSpecies }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump } = props;
  const { item, start_sec } = omniaVideoItem;
  const { display_name, image } = item;
  const { affiliateLinkParams } = useOmniaVideo();
  const url = getSpeciesUrl(item) + affiliateLinkParams;

  return (
    <>
      {image && (
        <div onClick={onClick} className={styles.img}>
          <img
            src={getImgixPath(image, { w: 200 })}
            alt={display_name}
            loading="lazy"
            width="200"
            height="200"
          />
        </div>
      )}

      <div className={styles.text}>
        <p className={styles.title} onClick={onClick}>
          Species: {display_name}
        </p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>

      <div className={styles.buttonPrice}>
        <LinkButton href={url} onClick={onClick} className={classNames(styles.button)}>
          Learn More
        </LinkButton>
      </div>
    </>
  );
};
