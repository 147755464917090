import { WebMercatorViewport } from '@math.gl/web-mercator';
import { WebMercatorViewportOptions } from '@math.gl/web-mercator/src/web-mercator-viewport';
import {
  Ambassador,
  Brand,
  Category,
  Product,
  SelectedOption,
  Species,
  Subcategory,
  TechniqueFull,
  Waterbody,
} from '@omniafishing/core';
import _ from 'lodash';
import queryString from 'query-string';

const OMNIA_BASE_URL = 'http://omniafishing.com';

export enum RoutePaths {
  ABOUT = '/about',
  AMBASSADOR_APPLICATION = '/ambassador-application',
  AMBASSADOR_APPLICATION_FORM = '/ambassador-application-form',
  AMBASSADORS = '/ambassadors',
  APP = '/app',
  ARTICLES = '/a',
  BEST_OF_2021 = '/best-of-2021',
  BEST_SELLERS = '/best-sellers',
  BLACK_FRIDAY_CYBER_MONDAY = '/black-friday-cyber-monday',
  BRANDS = '/b',
  CART_BINS = '/cart/bins',
  CART_CLEAR = '/cart/clear',
  CATEGORIES = '/c',
  CONFIRM = '/confirm',
  DASHBOARD = '/dashboard',
  DEALS = '/deals',
  DELIVERY_RETURN = '/delivery-return',
  EMAIL_SENT = '/email-sent',
  FAQS = '/faqs',
  FISHING_ARTICLES = '/fishing-articles',
  FISHING_REPORTS = '/fishing-reports',
  FISHING_REPORTS_RULES = '/fishing-reports-contest-rules-results',
  FISHING_REPORTS_WEEKLY = '/fishing-reports-weekly-giveaway',
  GET_STARTED = '/best-way-to-fish',
  GIFT_GUIDE = '/gift-guide',
  HOME = '/',
  HOW_IT_WORKS = '/how-it-works',
  JOBS = '/jobs',
  LANDING_PAGES = '/lp',
  LISTS = '/l',
  LOGIN = '/login',
  MAP = '/map',
  NEW = '/new',
  NEWS = '/news',
  OMNIA_VIDEOS = '/omnia-videos',
  ORGS = '/orgs',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RESET_CONFIRM = '/password-reset-confirm',
  PERSONAL_SHOPPING = '/personal-shopping',
  PREMIUM = '/premium',
  PRIVACY = '/privacy',
  PRODUCTS = '/p',
  SALE = '/sale',
  SEARCH = '/search',
  SHOP = '/shop',
  SIGNUP = '/signup',
  SIGNUP_FOLLOWUP = '/signup_followup',
  SIGNUP_GIVEAWAY = '/signup_giveaway',
  SPECIES = '/sp',
  STATES = '/states',
  TECHNIQUES = '/st',
  TERMS = '/terms',
  TOURNAMENT_SUBMIT = '/tournament-submit',
  TOURNAMENTS = '/tournaments',
  USERS = '/u',
  VIDEOS = '/videos',
  WATERS = '/w',
  WHO = '/who',
  WISHLIST = '/wishlist',
  WISHLIST_GIVEAWAY = '/wishlist-giveaway',
}

export const OPTION_DIVIDER = '--';
export const VARIANT_KEY_VALUE_DIVIDER = '*';
export const WHITESPACE_REPLACEMENT = '~';
export const SLASH_REPLACEMENT = '__';

export function encodeVariantUrl(productHandle: string, selectedOptions: SelectedOption[]) {
  const options = _.orderBy(selectedOptions, 'name').map(
    (option) =>
      `${option.name.toLowerCase()}${VARIANT_KEY_VALUE_DIVIDER}${option.value
        .toLowerCase()
        .replace(/\s/g, WHITESPACE_REPLACEMENT)
        .replace(/\//g, SLASH_REPLACEMENT)}`
  );
  const optionsEncoded = encodeURIComponent(options.join(OPTION_DIVIDER));
  return `${productHandle}/${optionsEncoded}`;
}

export const formatSecondsToMinutes = (s: number) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

export const toDollars = (amount: string | number) => {
  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const setShopifyImgWidth = (src: string, width: 30 | 100 | 200 | 300 | 500 | 1000) => {
  if (!src) {
    return null;
  }
  const srcSplit = src.split('.');
  const filePath = srcSplit[srcSplit.length - 2];
  srcSplit[srcSplit.length - 2] = `${filePath}_${width}x`;
  return srcSplit.join('.');
};

export const getProductFamilyUrl = (productHandle: string) => {
  return `${OMNIA_BASE_URL}${RoutePaths.PRODUCTS}/${productHandle}`;
};

export const getProductUrl = (productHandle: string, selectedOptions: SelectedOption[]) => {
  return `${OMNIA_BASE_URL}${RoutePaths.PRODUCTS}/${encodeVariantUrl(
    productHandle,
    selectedOptions
  )}`;
};

export const getSpeciesUrl = (species: Species) => {
  return `${OMNIA_BASE_URL}${RoutePaths.SPECIES}/${species.url_path}`;
};

export const getTechniqueUrl = (technique: TechniqueFull) => {
  return `${OMNIA_BASE_URL}${RoutePaths.TECHNIQUES}/${technique.url_path}`;
};

export const getWaterbodyUrl = (waterbody: Waterbody) => {
  return `${OMNIA_BASE_URL}${RoutePaths.WATERS}/${waterbody.url_slug}`;
};

export const getBrandUrl = (brand: Brand) => {
  return `${OMNIA_BASE_URL}${RoutePaths.BRANDS}/${brand.url_slug}`;
};

export const getAmbassadorUrl = (user: Ambassador) => {
  return `${OMNIA_BASE_URL}${RoutePaths.USERS}/${user.slug}`;
};

export const getCategoryUrl = (category: Category) => {
  return `${OMNIA_BASE_URL}${RoutePaths.CATEGORIES}/${category.url_path}`;
};

export const getSubcategoryUrl = (category: Category, subcategory: Subcategory) => {
  if (!category || !subcategory) {
    return '#';
  }

  return `${RoutePaths.CATEGORIES}/${category.url_path}/${subcategory.url_path}`;
};

export const IMGIX_URL = 'omnia-fishing.imgix.net';
export const PRISMIC_IMGIX_URL = 'images.prismic.io';

// from https://docs.imgix.com/apis/url
interface ImgixFormatting {
  [key: string]: unknown; // to "fix" a TS3.5.1 error
  w?: number;
  h?: number;
  auto?: 'format';
  q?: number;
}

export const getImgixPath = (path: string, options: ImgixFormatting = {}) => {
  if (
    !path ||
    (path.indexOf(IMGIX_URL) === -1 && path.indexOf(PRISMIC_IMGIX_URL) === -1) ||
    _.isEmpty(options)
  ) {
    return path;
  }

  const basePath = path.split('?')[0];
  const query = path.split('?')[1];

  const existingParams = queryString.parse(query);
  const newParams = existingParams
    ? {
        ...existingParams,
        ...options,
      }
    : options;

  const queryParams = queryString.stringify(newParams);

  return `${basePath}?${queryParams}`;
};

// bounds = opposite corners specified as [[lon, lat], [lon, lat]]
export function fitBounds(
  bounds: [[number, number], [number, number]],
  viewport: WebMercatorViewportOptions,
  padding = 60
) {
  return new WebMercatorViewport(viewport).fitBounds(bounds, { padding });
}

export function getBounds(
  contentRect: {
    width: number;
    height: number;
  },
  bbox: number[]
) {
  return fitBounds(
    [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
    {
      width: contentRect.width,
      height: contentRect.height,
    },
    30
  );
}

export const NAME_FALLBACK = 'Omnia Community Member';

export function getUserFullname(user: { first_name: string; last_name: string }) {
  if (!user) {
    return '';
  }

  const { first_name, last_name } = user;
  const first = first_name && first_name.trim();
  const last = last_name && last_name.trim();
  if (first_name && last_name) {
    return `${first} ${last}`;
  }
  if (first_name && !last_name) {
    return first;
  }
  if (!first_name && last_name) {
    return last;
  }

  return '';
}

interface QueryParams {
  env: Env;
  id: string;
}

export const getQueryParams = () => {
  const { id, env } = queryString.parse(window.location.search) as unknown as QueryParams;

  if (!id) {
    console.error('Video ID is missing. Make sure your embed code contains a data-id attribute');
  }

  return {
    id,
    env,
  };
};

export const isProductInStock = (p: Product) => {
  if (!p) {
    return false;
  }

  if (!p.inventory_tracked) {
    return true;
  }
  // inventory is tracked
  if (p.inventory > 0) {
    return true;
  }

  return false;
};

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
