import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useProductFamilyModal } from '../../hooks/use_product_family_modal';
import { ProductFamilySelector } from './product_family_selector';

export const ProductFamilyModal = () => {
  const { visible, hide, productFamily } = useProductFamilyModal();
  const products = productFamily?.products || [];

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={hide}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[880px] transform bg-white shadow-xl transition-all">
                  <div className="absolute right-1 top-1 cursor-pointer text-2xl" onClick={hide}>
                    <IoMdClose />
                  </div>
                  {productFamily && (
                    <ProductFamilySelector
                      productFamily={productFamily}
                      products={products}
                      key={`${productFamily.handle}`}
                      visible={visible}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
