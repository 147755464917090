import { LineItem, OmniaResponse } from '@omniafishing/core';
import Cookies from 'js-cookie';
import { createContext, useCallback, useEffect, useState } from 'react';
import { axiosOmniaV1 } from '../axios_omnia';
import { CookieKeys, getCookieConfig } from '../lib/cookies';

interface CartContextValue {
  checkoutId: string;
  setCheckoutId: (checkout_id: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;
  checkout: Checkout;
  setCheckout: (checkout: Checkout) => void;
}

export const CartContext = createContext<CartContextValue>(null);

interface CartProviderProps {
  children: React.ReactNode;
}

interface Checkout {
  lineItems: LineItem[];
}

interface FetchCheckoutIdResponse {
  checkout_id: string;
  was_checkout_complete: boolean;
}

export const CartProvider = (props: CartProviderProps) => {
  const { children } = props;
  const guest_checkout_id = Cookies.get(CookieKeys.checkoutId);

  const [checkoutId, setCheckoutId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [checkout, setCheckout] = useState<Checkout>(undefined);

  const toggleOpen = () => setOpen((open) => !open);

  const fetchCheckoutId = useCallback(async () => {
    const res = await axiosOmniaV1.post<OmniaResponse<FetchCheckoutIdResponse>>('/cart', {
      guest_checkout_id,
      merge_checkout_id: null,
    });
    const { checkout_id } = res.data.data;
    setCheckoutId(checkout_id);
    Cookies.set(CookieKeys.checkoutId, checkout_id, getCookieConfig());
  }, [guest_checkout_id, setCheckoutId]);

  useEffect(() => {
    fetchCheckoutId();
  }, [fetchCheckoutId]);

  const value: CartContextValue = {
    checkoutId,
    setCheckoutId,
    open,
    setOpen,
    toggleOpen,
    checkout,
    setCheckout,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
