import React from 'react';

export enum MapPinTypes {
  NORMAL = 'NORMAL',
  SELECTED = 'SELECTED',
  FAVORITE = 'FAVORITE',
  FEATURED = 'FEATURED',
  HOT = 'HOT',
}

export interface MapPinProps {
  size: number;
  onClick: () => void;
  type: MapPinTypes;
  className?: string;
}

export const FILL_SELECTED = '#1890ff';
export const FILL_FAVORITE = '#FFC07A';
export const FILL_FEATURED = '#e84b25';
export const FILL_DEFAULT = '#28424F';

export default class MapPin extends React.PureComponent<MapPinProps, {}> {
  get Fill() {
    const { type } = this.props;
    switch (type) {
      case MapPinTypes.SELECTED:
        return FILL_SELECTED;
      case MapPinTypes.FAVORITE:
        return FILL_FAVORITE;
      case MapPinTypes.FEATURED:
      case MapPinTypes.HOT:
        return FILL_FEATURED;
      default:
        return FILL_DEFAULT;
    }
  }

  render() {
    const { size, onClick, className } = this.props;
    const fill = this.Fill;
    const width = size * 0.8;
    const height = size;

    return (
      <svg
        viewBox={`0 0 36 48`}
        width={width}
        height={height}
        onClick={onClick}
        className={className}
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M16.125 47.062a743.526 743.526 0 0 0-6.281-9c-3.125-4.5-5.188-7.53-6.188-9.093-1.437-2.25-2.406-4.14-2.906-5.672C.25 21.766 0 20 0 18c0-3.25.812-6.25 2.437-9A18.344 18.344 0 0 1 9 2.438C11.75.813 14.75 0 18 0s6.25.813 9 2.438A18.344 18.344 0 0 1 33.562 9C35.187 11.75 36 14.75 36 18c0 2-.25 3.766-.75 5.297-.5 1.531-1.469 3.422-2.906 5.672-1 1.562-3.063 4.594-6.188 9.094l-6.281 9c-.438.624-1.063.937-1.875.937-.813 0-1.438-.313-1.875-.938z"
            fill={fill}
          />
          <path
            d="M20.6736955,11 L15.3255586,11 C12.9367082,11 11,12.8351341 11,15.0986969 L11,19.1017006 L14.9432292,16.8234374 L14.9432292,15.1649896 C14.9432292,14.5749989 15.447731,14.0968152 16.0703775,14.0968152 L19.929175,14.0968152 C20.5521198,14.0968152 21.05692,14.5752816 21.05692,15.1652723 L21.05692,22.834869 C21.05692,23.4247184 20.552269,23.9029021 19.9296225,23.9029021 L16.0001172,23.9029021 C14.3532514,23.9029021 14.6377236,22.2823358 15.1678831,21.5893016 L16.3012967,22.1448032 C16.0559078,20.7792305 16.4543478,18.1952295 17.0107617,16.6808163 C13.6285708,18.8669199 11,20.6073501 11,23.4724944 C11,25.2590044 12.4186317,27 14.9462126,27 L14.9462126,27 L20.6742922,27 C23.063441,27 25,25.1647246 25,22.9011617 L25,15.0994037 C25,12.8354168 23.0631426,11 20.6736955,11"
            fill="#fff"
          />
        </g>
      </svg>
    );
  }
}
