import classNames from 'classnames';
import { useContext } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useCheckout } from '../../hooks/use_checkout';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { CartContext } from '../../providers/cart_provider';
import logo from './logo.svg';

export const Header = () => {
  const { checkoutId, toggleOpen } = useContext(CartContext);
  const { data, isSuccess } = useCheckout(checkoutId);
  const { affiliateLinkParams } = useOmniaVideo();

  return (
    <header className="px-2 py-1">
      <div className="flex items-center justify-between">
        <a
          href={`https://www.omniafishing.com/${affiliateLinkParams}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={logo} alt="Omnia Fishing" width={180} />
        </a>
        <button
          className={classNames(
            'flex items-center gap-1 rounded-full border border-blue-500 py-2 px-4 shadow transition-all hover:bg-gray-50'
          )}
          onClick={toggleOpen}
        >
          <FaShoppingCart /> {isSuccess && data.lineItems.length}
        </button>
      </div>
    </header>
  );
};
