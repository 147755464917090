import { OmniaVideo, OmniaVideoItem } from '@omniafishing/core';
import classNames from 'classnames';

import { PlyrEvent } from 'plyr';
import { APITypes } from 'plyr-react';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDynamicRefs } from '../../hooks/use_dyamic_refs';
import { useResponsive } from '../../hooks/use_responsive';
import { SegmentAnalytics } from '../../lib/segment';
import { Divider } from '../divider/divider';
import { VideoItem } from '../video_item/video_item';
import VideoPlayer from '../video_player/video_player';
import styles from './video.module.scss';

interface VideoProps {
  omniaVideo: OmniaVideo;
  omniaVideoItems: OmniaVideoItem[];
  onVideoLoad?: () => void;
}

export const Video = (props: VideoProps) => {
  const { omniaVideo, omniaVideoItems, onVideoLoad } = props;
  const [plyrInstance, setPlyrInstance] = useState<APITypes['plyr']>();
  const [getRef, setRef] = useDynamicRefs();
  const [currentVideoItemId, setCurrentVideoItemId] = useState(null as number);
  const productsRef = useRef<HTMLDivElement>();
  const { isMobile } = useResponsive();

  const { title, mux_playback_id, slug, publication_date, livestream, source, thumbnail } =
    omniaVideo;
  const isYoutube = source?.includes('youtube.com') || false;

  const seek = (time: number) => () => {
    if (plyrInstance) {
      plyrInstance.currentTime = time;
      plyrInstance.play();
    }
  };

  const onTimeUpdate = useCallback(
    (event: PlyrEvent) => {
      const sec = Math.floor(event.detail.plyr.currentTime);
      const currentVideoItem = omniaVideoItems.find((p) => sec >= p.start_sec && sec <= p.end_sec);

      if (currentVideoItem && currentVideoItem.id !== currentVideoItemId) {
        setCurrentVideoItemId(currentVideoItem.id);
      }
      if (!currentVideoItem) {
        setCurrentVideoItemId(null);
      }
    },
    [currentVideoItemId, omniaVideoItems]
  );

  const scrollProductsToRef = useCallback(
    (currentRef: RefObject<HTMLDivElement>) => {
      const strollOptions: ScrollToOptions = isMobile
        ? {
            left: currentRef.current.offsetLeft,
            behavior: 'smooth',
          }
        : {
            top: currentRef.current.offsetTop,
            behavior: 'smooth',
          };

      productsRef.current.scrollTo(strollOptions);
    },
    [isMobile]
  );

  useEffect(() => {
    // video player loaded
    if (plyrInstance) {
      plyrInstance.on('timeupdate', onTimeUpdate as any);
      plyrInstance.on('canplay', () => onVideoLoad?.());
      plyrInstance.on('loadedmetadata', () => onVideoLoad?.());
    }
  }, [plyrInstance, onTimeUpdate, onVideoLoad]);

  useEffect(() => {
    if (currentVideoItemId) {
      const currentRef = getRef(currentVideoItemId.toString()) as RefObject<HTMLDivElement>;
      if (currentRef) {
        scrollProductsToRef(currentRef);
      }
    }
  }, [currentVideoItemId, getRef, scrollProductsToRef]);

  const onPlayPause = useCallback(
    (event: PlyrEvent) => {
      if (!plyrInstance) {
        return;
      }

      const playbackStatus = event.type === 'ended' ? 'completed' : event.type;

      if (playbackStatus === 'pause' && plyrInstance.duration === event.detail.plyr.currentTime) {
        return;
      }
      SegmentAnalytics.shoppableVideoPlaybackStatus({
        video_title: title,
        video_pub_date: publication_date,
        video_slug: slug,
        status: playbackStatus,
      });
    },
    [plyrInstance, title, publication_date, slug]
  );

  useEffect(() => {
    if (!plyrInstance) {
      return;
    }

    plyrInstance.on('play', (e) => onPlayPause(e));
    plyrInstance.on('pause', (e) => onPlayPause(e));
    plyrInstance.on('ended', (e) => onPlayPause(e));
  }, [plyrInstance, onPlayPause]);

  return (
    <section className="flex flex-col sm:flex-row">
      <div className="sm:w-2/3">
        <div>
          <VideoPlayer
            muxPlaybackId={mux_playback_id}
            source={
              isYoutube
                ? {
                    type: 'video',
                    sources: [
                      {
                        src: source,
                        provider: 'youtube',
                      },
                    ],
                  }
                : {
                    type: 'video',
                    poster: thumbnail,
                    sources: [
                      {
                        src: 'currently the muxid creates the source internally',
                      },
                    ],
                  }
            }
            ref={(player) => {
              if (player?.plyr) {
                setPlyrInstance(player.plyr);
              }
            }}
            options={
              {
                // debug: true,
              }
            }
          />
        </div>
      </div>
      <div className="relative overflow-auto sm:w-1/3" ref={productsRef}>
        <div className="flex w-full flex-row sm:absolute sm:flex-col">
          {omniaVideoItems.map((ovi, i) => (
            <React.Fragment key={ovi.id}>
              {i !== 0 && <Divider vertical={isMobile} />}

              <div
                className={classNames(styles.videoItem)}
                ref={setRef(ovi.id.toString()) as RefObject<HTMLDivElement>}
              >
                <VideoItem
                  omniaVideoItem={ovi}
                  onClick={() => {
                    SegmentAnalytics.shoppableVideoItemClick({
                      video_title: title,
                      video_pub_date: publication_date,
                      video_slug: slug,
                      is_livestream: livestream,
                      is_top_item: i === 0,
                      item_id: ovi.item_id,
                      item_type: ovi.item_type,
                    });
                  }}
                  onJumpClick={seek(ovi.start_sec)}
                  selected={currentVideoItemId === ovi.id}
                  showJump={true}
                  position={i}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};
