import classNames from 'classnames';
import React from 'react';
import { getImgixPath } from '../../lib';
import styles from './user_profile_img.module.scss';

interface UserProfileImgProps {
  image: string;
  imageSize: number;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

export const UserProfileImg = (props: UserProfileImgProps) => {
  const { image, imageSize, size, style, className } = props;

  return (
    <div
      className={classNames(styles.imgWrapper, className)}
      style={{
        ...style,
        width: size,
      }}
    >
      <img
        className={styles.img}
        alt=""
        src={getImgixPath(image, {
          fit: 'crop',
          mask: 'ellipse',
          w: imageSize,
          h: imageSize,
        })}
        loading="lazy"
        width={size || imageSize}
        height={size || imageSize}
      />
    </div>
  );
};
