import classNames from 'classnames';

interface DividerProps {
  vertical?: boolean;
  margin?: number;
}

export const Divider = (props: DividerProps) => {
  const { vertical, margin } = props;

  const horizontal = !vertical;
  const vert = !horizontal;
  return (
    <div
      className={classNames(' border-gray-300', {
        'border-t': horizontal,
        'my-1': horizontal && margin === 1,
        'my-2': horizontal && margin === 2,
        'my-3': horizontal && margin === 3,
        'my-4': horizontal && margin === 4,
        'my-5': horizontal && margin === 5,
        'my-6': horizontal && margin === 6,
        'my-7': horizontal && margin === 7,
        'my-8': horizontal && margin === 8,
        'my-9': horizontal && margin === 9,
        'border-l': vert,
        'mx-1': vert && margin === 1,
        'mx-2': vert && margin === 2,
        'mx-3': vert && margin === 3,
        'mx-4': vert && margin === 4,
        'mx-5': vert && margin === 5,
        'mx-6': vert && margin === 6,
        'mx-7': vert && margin === 7,
        'mx-8': vert && margin === 8,
        'mx-9': vert && margin === 9,
      })}
    ></div>
  );
};
