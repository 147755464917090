import { useContext } from 'react';
import { ModalContext } from '../providers/modal_provider';

export const useProductFamilyModal = () => {
  const {
    productFamilyModalVisible,
    showProductFamilyModal,
    hideProductFamilyModal,
    productFamilyModalProductFamily,
  } = useContext(ModalContext);

  return {
    visible: productFamilyModalVisible,
    show: showProductFamilyModal,
    hide: hideProductFamilyModal,
    productFamily: productFamilyModalProductFamily,
  };
};
