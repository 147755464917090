import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { getEnv } from './env';

const V1 = 'v1/';

export const axiosOmniaV1 = axios.create({
  baseURL: getApiBaseUrlV1(),
  paramsSerializer: (params) => {
    return queryString.stringify(params, { arrayFormat: 'bracket' });
  },
});

export function getApiBaseUrlV1() {
  const apiUrl = getEnv().API_BASE_URL;
  return `${apiUrl}${V1}`;
}

export function shouldIncludeAuthHeaderV1(config: AxiosRequestConfig) {
  const apiBaseUrl = getApiBaseUrlV1();
  return (
    config.baseURL === apiBaseUrl &&
    config.url.split('/')[0] !== 'auth' &&
    config.url.split('/')[0].indexOf('http') === -1 &&
    !config.headers.Authorization
  );
}

axiosOmniaV1.interceptors.request.use((config) => {
  if (shouldIncludeAuthHeaderV1(config)) {
    const accessToken = Cookies.get('omnia_embed_access_token');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});
