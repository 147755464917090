import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './App';
import './index.scss';
import { CartProvider } from './providers/cart_provider';
import { ModalProvider } from './providers/modal_provider';
import { OmniaVideoProvider } from './providers/omnia_video_provider';
import { ReferenceDataProvider } from './providers/reference_data_provider';
import reportWebVitals from './reportWebVitals';

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReferenceDataProvider>
        <CartProvider>
          <OmniaVideoProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </OmniaVideoProvider>
        </CartProvider>
      </ReferenceDataProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
