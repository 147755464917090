import { Checkout } from '@omniafishing/core';
import { gql } from 'graphql-request';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { QueryIds, shopifyClient } from '../clients';
import { CheckoutFragment } from '../fragments';

export interface LineItemToAdd {
  variantId: string;
  quantity: number;
  customAttributes?: LineItemCustomAttribute[];
}

export interface LineItemCustomAttribute {
  key: LINE_ATTRIBUTION;
  value: string;
}

export enum LINE_ATTRIBUTION {
  AFFILIATE = 'affiliate',
  AMBASSADOR_CODE = 'ambassador_code',
  FISHING_REPORT_ID = 'fishing_report_id',
  OMNIA_VIDEO_ID = 'omnia_video_id',
  PAGE_TYPE = 'page_type',
  URL = 'url',
  WATERBODY_ID = 'waterbody_id',
}

const checkoutLineItemsAddMutation = gql`
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const useAddToCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (args: {
      checkoutId: string;
      lineItem: LineItemToAdd;
      customAttributes?: LineItemCustomAttribute[];
    }) => {
      const { checkoutId, lineItem, customAttributes } = args;
      const uniqCustomAttributes = _.uniqBy(customAttributes, 'key');

      const res = await shopifyClient.request<{ checkoutLineItemsAdd: { checkout: Checkout } }>(
        checkoutLineItemsAddMutation,
        {
          checkoutId,
          lineItems: [lineItem],
          customAttributes: uniqCustomAttributes,
        }
      );

      return res.checkoutLineItemsAdd.checkout;
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(QueryIds.checkout, data);
      },
    }
  );
};
