import { OmniaVideoItemWaterbody } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { formatSecondsToMinutes, getWaterbodyUrl } from '../../lib';
import { LinkButton } from '../link_button/link_button';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemWaterbody = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemWaterbody }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump } = props;
  const { item, start_sec } = omniaVideoItem;
  const { primary_name } = item;
  const { affiliateLinkParams } = useOmniaVideo();
  const url = getWaterbodyUrl(item) + affiliateLinkParams;

  return (
    <>
      <div onClick={onClick} className={styles.img}>
        <WaterbodyImg waterbody={item} />
      </div>

      <div className={styles.text}>
        <p className={styles.title} onClick={onClick}>
          {primary_name}
        </p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>

      <div className={styles.buttonPrice}>
        <LinkButton href={url} onClick={onClick} className={classNames(styles.button)}>
          Learn More
        </LinkButton>
      </div>
    </>
  );
};
