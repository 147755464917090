import { OmniaVideoItemProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import { AiFillFastForward } from 'react-icons/ai';
import { useProductFamilyModal } from '../../hooks/use_product_family_modal';
import { formatSecondsToMinutes, setShopifyImgWidth, toDollars } from '../../lib';
import { SegmentAnalytics } from '../../lib/segment';
import { Button } from '../button/button';
import { Spacer } from '../spacer/spacer';
import { VideoItemProps } from './video_item';
import styles from './video_item.module.scss';

export const VideoItemProductFamily = (
  props: VideoItemProps & { omniaVideoItem: OmniaVideoItemProductFamily }
) => {
  const { onClick, omniaVideoItem, onJumpClick, showJump, position } = props;
  const { show } = useProductFamilyModal();

  const { item, start_sec } = omniaVideoItem;
  const { title, img_url, price_min, price_max, products } = item;

  let priceRange = `${price_min} – ${price_max}`;
  if (price_min === price_max) {
    priceRange = `${price_min}`;
  }
  const hasCompareAt = products.some((product) => product.on_sale);
  const highestCompareAt = products
    .map((p) => Number(p.compare_at_price))
    .filter(Boolean)
    .sort()[0];

  const clickHandler = () => {
    onClick?.();
    SegmentAnalytics.productClick({
      productOrProductFamily: item,
      show_modal: true,
      position,
    });
    show(item);
  };

  return (
    <>
      <div onClick={clickHandler} className={styles.img}>
        <img
          src={setShopifyImgWidth(img_url, 200)}
          alt={''}
          loading="lazy"
          width="200"
          height="200"
        />
      </div>
      <div className={styles.text}>
        <p className={styles.title} onClick={clickHandler}>
          {title}
        </p>
        {showJump && (
          <p className={styles.jump} onClick={onJumpClick}>
            <AiFillFastForward className={styles.jumpIcon} />
            Jump to {formatSecondsToMinutes(start_sec)}
          </p>
        )}
      </div>
      <div className={styles.buttonPrice}>
        <Button onClick={clickHandler} className={styles.button}>
          Quick Look
        </Button>

        <Spacer size={12} />

        <p className={styles.price}>
          <span
            className={classNames({
              [styles.price__sale]: hasCompareAt,
            })}
          >
            ${priceRange}
          </span>
          {hasCompareAt && <s className={styles.priceCompare}>{toDollars(highestCompareAt)}</s>}
        </p>
      </div>
    </>
  );
};
