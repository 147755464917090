import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import styles from './app.module.scss';
import { Cart } from './components/cart/cart';
import { Header } from './components/header/header';
import Loading from './components/loading/loading';
import { ProductFamilyModal } from './components/product_family_modal/product_family_modal';
import { Video } from './components/video/video';
import { useOmniaVideo } from './hooks/use_omnia_video';
import { getQueryParams } from './lib';
import './styles/base.scss';

interface ResizeAction {
  type: 'resize';
  payload: {
    w: number;
    h: number;
  };
}

export const App = () => {
  const { id } = getQueryParams();
  const { omniaVideo, omniaVideoItems, loading, error } = useOmniaVideo();

  const onResize = () => {
    const resizeAction: ResizeAction = {
      type: 'resize',
      payload: {
        w: document.body.clientWidth,
        h: document.body.clientHeight,
      },
    };
    window.parent.postMessage(resizeAction, '*');
  };

  const onResizeDebounced = debounce(() => {
    onResize();
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', onResizeDebounced);
    return () => {
      window.removeEventListener('resize', onResizeDebounced);
    };
  }, [onResizeDebounced]);

  return (
    <>
      <Header />
      <main>
        {id == null ? (
          <>
            <div className="p-2">
              <p>Video ID is missing. Please double-check your embed code.</p>
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <Loading text="Loading Video" />
            ) : error ? (
              <div className={styles.error}>Sorry, there was an error loading the video</div>
            ) : (
              <>
                {omniaVideo && (
                  <Video
                    omniaVideo={omniaVideo}
                    omniaVideoItems={omniaVideoItems}
                    onVideoLoad={onResize}
                  />
                )}
              </>
            )}
          </>
        )}
        <Cart />
      </main>
      <ProductFamilyModal />
    </>
  );
};
