import { getEnv } from '../env';

export enum CookieKeys {
  refreshToken = 'omnia_refreshToken',
  accessToken = 'omnia_accessToken',
  accessTokenExpiration = 'omnia_accessTokenExpiration',
  checkoutId = 'omnia_checkoutId',
}

export function getCookieConfig(): Cookies.CookieAttributes {
  const isLocal = getEnv().ENV === 'localhost';

  return {
    expires: 365,
    secure: isLocal ? false : true,
    sameSite: 'Lax',
  };
}
