import { Product } from '@omniafishing/core';
import { useContext } from 'react';
import { LINE_ATTRIBUTION, useAddToCart } from '../../hooks/use_add_to_cart';
import { useOmniaVideo } from '../../hooks/use_omnia_video';
import { AddToCartAreas, SegmentAnalytics } from '../../lib/segment';
import { CartContext } from '../../providers/cart_provider';
import { Button, ButtonColors } from '../button/button';

interface AddToCartButtonProps extends Partial<Omit<HTMLButtonElement, 'children'>> {
  children: React.ReactNode;
  product: Product;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  quantity: number;
  onAddedToCart?: () => void;
  color?: ButtonColors;
}

export const AddToCartButton = (props: AddToCartButtonProps) => {
  const { children, onClick, type, product, quantity, onAddedToCart, color, disabled } = props;
  const { checkoutId } = useContext(CartContext);
  const addToCartMutation = useAddToCart();
  const { omniaVideo } = useOmniaVideo();

  const customAttributes = [
    {
      key: LINE_ATTRIBUTION.PAGE_TYPE,
      value: 'Embedded Video',
    },
    {
      key: LINE_ATTRIBUTION.OMNIA_VIDEO_ID,
      value: omniaVideo.id.toString(),
    },
  ];

  if (omniaVideo.affiliate != null) {
    customAttributes.push({
      key: LINE_ATTRIBUTION.AFFILIATE,
      value: omniaVideo.affiliate.campaign_source,
    });
  }

  const onButtonClick = () => {
    addToCartMutation.mutate(
      {
        checkoutId,
        lineItem: {
          variantId: product.shopify_graphql_id,
          quantity,
          customAttributes,
        },
      },
      {
        onSuccess: () => {
          onAddedToCart?.();
        },
      }
    );
    SegmentAnalytics.addToCart(product, AddToCartAreas.MAIN_VIEW);
    onClick?.();
  };

  return (
    <Button type={type ?? 'button'} onClick={onButtonClick} color={color} disabled={disabled}>
      {children}
    </Button>
  );
};
