import { Waterbody } from '@omniafishing/core';
import { getEnv } from '../../env';
import { fitBounds } from '../../lib';
import { FILL_DEFAULT, FILL_FEATURED } from './map_pin';

type WaterbodySubset = Pick<Waterbody, 'bbox' | 'lat' | 'lng' | 'primary_name' | 'hot_scale'>;

interface WaterbodyImgProps {
  waterbody: WaterbodySubset;
  size?: {
    width: number;
    height: number;
    padding?: number;
  };
  className?: string;
  hires?: boolean;
  width?: number;
  height?: number;
}

export const waterbodyImgUrl = (
  waterbody: WaterbodySubset,
  imgSize: {
    width: number;
    height: number;
    padding?: number;
  },
  hires: boolean
) => {
  if (waterbody == null) {
    return null;
  }

  const color =
    waterbody.hot_scale > 0 ? FILL_FEATURED.replace('#', '') : FILL_DEFAULT.replace('#', '');

  const res = hires ? '@2x' : '';

  const zoom = waterbody.bbox
    ? fitBounds(
        [waterbody.bbox[0], waterbody.bbox[2]],
        {
          width: imgSize.width,
          height: imgSize.height,
        },
        imgSize?.padding
      ).zoom
    : 12;

  return `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+${color}(${
    waterbody.lng
  },${waterbody.lat})/${waterbody.lng},${waterbody.lat},${zoom},0.00,0.00/${imgSize.width}x${
    imgSize.height
  }${res}?access_token=${getEnv().MAPBOX_ACCESS_TOKEN}`;
};

export const WaterbodyImg = (props: WaterbodyImgProps) => {
  const { waterbody, size, className, hires, width, height } = props;

  const imgSize = {
    width: 400,
    height: 300,
    ...size,
  };

  return (
    <img
      src={waterbodyImgUrl(waterbody, imgSize, hires)}
      alt={`${waterbody.primary_name} Map`}
      className={className}
      width={width}
      height={height}
    />
  );
};
