import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { useCheckout } from '../../hooks/use_checkout';
import { toDollars } from '../../lib';
import { CartContext } from '../../providers/cart_provider';
import { LinkButton } from '../link_button/link_button';
import { CartItem } from './cart_item';
export const SHIPPING_FREE_CUTOFF = 50.0;
export const SHIPPING_GROUND_PRICE_CENTS = 500;
export const SHIPPING_GROUND_PRICE_DOLLARS = SHIPPING_GROUND_PRICE_CENTS / 100;
export const SHIPPING_EXPRESS_PRICE_DOLLARS = 6;

export const Cart = () => {
  const { open, setOpen, checkoutId } = useContext(CartContext);
  const { data } = useCheckout(checkoutId);
  const { subtotalPrice, lineItems, webUrl, discounts, giftCards } = data;
  const hasDiscounts = discounts.length > 0;
  const hasGiftCards = giftCards.length > 0;
  const discountAmount = 0;
  const giftCardsAppliedCents =
    giftCards.reduce((acc, giftCard) => acc + Number(giftCard.amountUsed.amount), 0) * 100;
  const giftCardsAmount = toDollars(giftCardsAppliedCents / 100);
  const shippingAmountCents =
    parseFloat(subtotalPrice) >= SHIPPING_FREE_CUTOFF ? 0 : SHIPPING_GROUND_PRICE_CENTS;
  const shippingAmount = shippingAmountCents > 0 ? toDollars(shippingAmountCents / 100) : 'FREE';
  const subtotalInCents = parseFloat(subtotalPrice) * 100;
  const cartTotal = toDollars(
    (subtotalInCents + shippingAmountCents - giftCardsAppliedCents) / 100
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-2xl text-white opacity-75 hover:opacity-100"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <IoMdCloseCircle />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex h-full flex-col bg-gray-100 shadow-xl">
                    <div className="flex-shrink-0 flex-grow-0 border-b bg-white p-6 text-center">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {toDollars(subtotalPrice)} Subtotal
                      </Dialog.Title>
                      <p className="text-xs">{lineItems.length} items</p>
                    </div>

                    <div className="flex flex-1 flex-col overflow-auto pt-6">
                      <div className="mb-6 divide-y border-y bg-white">
                        {lineItems.length > 0 ? (
                          lineItems.map((lineItem) => (
                            <CartItem lineItem={lineItem} key={lineItem.id} />
                          ))
                        ) : (
                          <div className="p-6 text-center text-gray-400">
                            <p>Your cart is empty</p>
                          </div>
                        )}
                      </div>

                      <div className="mb-6 border-y bg-white p-6">
                        <p className="mb-2 text-xl font-bold">Order Summary</p>
                        <table className="w-full table-fixed text-sm">
                          <tbody>
                            <tr>
                              <td>Subtotal:</td>
                              <td className="text-right">{toDollars(subtotalPrice)}</td>
                            </tr>
                            {hasDiscounts && (
                              <tr>
                                <td>Discounts:</td>
                                <td className="text-right">-{toDollars(discountAmount)}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Shipping:</td>
                              <td className="text-right">{shippingAmount}</td>
                            </tr>
                            <tr>
                              <td>Sales Tax:</td>
                              <td className="text-right">See at checkout</td>
                            </tr>
                            {hasGiftCards && (
                              <tr>
                                <td>Rewards:</td>
                                <td className="text-right">-{giftCardsAmount}</td>
                              </tr>
                            )}
                            <tr>
                              <td>
                                <strong>Total:</strong>
                              </td>
                              <td className="text-right">
                                <strong>{cartTotal}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 flex-grow-0 justify-center border-t bg-white p-6">
                      <LinkButton href={webUrl} color="orange" target="_blank">
                        Check Out
                      </LinkButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
